import { useNavigate, useParams } from "react-router-dom";
import "../../../style/categorydetail.css";
import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SUBCATEGORY } from "../../../graphql/queries/productQueries";
import { DELETE_SUBCATEGORY, UPDATE_SUBCATEGORY } from "../../../graphql/mutation/productMutations";
import LoadingButton from "../../../modules/icons/loading-button";
import toast, { Toaster } from "react-hot-toast";

const SubcategoryDetail = () => {
  const navigate = useNavigate();
  const { subcategory_id } = useParams();
  const [subcategoryName, setSubcategoryName] = useState(""); // Store the subcategory name
  const [isLoading, setIsLoading] = useState(true);

  // Fetch subcategory details
  const { data, loading, error } = useQuery(GET_SUBCATEGORY, {
    variables: { id: subcategory_id },
    onCompleted: (data) => {
      if (data?.subcategory_by_pk) {
        setSubcategoryName(data.subcategory_by_pk.subcategory_name);
        setIsLoading(false);
      }
    },
  });

  const [updateSubcategory, { loading: updateSubcategoryLoading }] = useMutation(
    UPDATE_SUBCATEGORY,
    {
      onCompleted: () => toast.success("Subcategory Updated"),
    }
  );

  const [deleteSubcategory, { loading: deleteLoading }] = useMutation(DELETE_SUBCATEGORY, {
    onCompleted: () => {
      navigate("/dashboard/createproduct", { state: { refetch: true } }); // Redirect to product list after deletion
    },
  });

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateSubcategory({
        variables: { id: subcategory_id, subcategory_name: subcategoryName },
      });
    } catch (err) {
      console.error("Error updating subcategory", err);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this subcategory?")) {
      const hasProducts = data.subcategory_by_pk.products.length > 0;

      if (hasProducts) {
        alert(
          `Cannot delete this subcategory because it has related products. Please remove or reassign them first.`
        );
      } else {
        try {
          await deleteSubcategory({ variables: { id: subcategory_id } });
          alert("Subcategory deleted successfully!");
        } catch (err) {
          console.error("Error deleting subcategory", err);
        }
      }
    }
  };

  if (loading || isLoading) return <p>Loading...</p>;

  return (
    <div className="category-detail-container">
      <Toaster />
      <div className="category-detail-container-layout">
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingLeft: "2rem",
          }}
        >
          <button
            onClick={() =>
              navigate("/dashboard/createproduct", { state: { refetch: true } })
            }
            className="category-detail-back-btn"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleUpdate} action="">
          <h2>Subcategory</h2>
          <input
            value={subcategoryName}
            onChange={(e) => setSubcategoryName(e.target.value)}
            placeholder={subcategoryName}
            type="text"
            name=""
            id=""
          />
          <div className="category-detail-btn-container">
            <button type="submit">
              {updateSubcategoryLoading ? <LoadingButton /> : "Update"}
            </button>
            <button onClick={handleDelete} type="button">
              {deleteLoading ? <LoadingButton /> : "Delete"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubcategoryDetail;
