import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../style/categorydetail.css";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CATEGORY } from "../../../graphql/queries/productQueries";
import {
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../../graphql/mutation/productMutations";
import LoadingButton from "../../../modules/icons/loading-button";
import toast, { Toaster } from "react-hot-toast";

const CategoryDetail = () => {
  const navigate = useNavigate();
  const { category_id } = useParams();
  const [categoryName, setCategoryName] = useState(""); // Store the category name
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const { data, loading, error, refetch:refetchCategory } = useQuery(GET_CATEGORY, {
    variables: { id: category_id },
    onCompleted: (data) => {
      if (data?.category_by_pk) {
        setCategoryName(data.category_by_pk.category_name);
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (location.state?.refetch) {
      refetchCategory();
    }
  }, [location.state, refetchCategory]);

  const [updateCategory, { loading: updateCategoryLoading }] = useMutation(
    UPDATE_CATEGORY,
    {
      onCompleted: () => toast.success("Category Updated"),
    }
  );

  const [deleteCategory, { loading: deleteLoading }] = useMutation(
    DELETE_CATEGORY,
    {
      onCompleted: () => {
        navigate("/dashboard/createproduct", { state: { refetch: true } }); // Redirect to category list after deletion
      },
    }
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateCategory({
        variables: { id: category_id, category_name: categoryName },
      });
    } catch (err) {
      console.error("Error updating category", err);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const hasProducts = data.category_by_pk.products.length > 0;
      const hasSubcategories = data.category_by_pk.subcategories.length > 0;
      if (hasProducts || hasSubcategories) {
        // Show a warning message if the category has products or subcategories
        alert(
          `Cannot delete this category because it has ${
            hasProducts ? "related products" : ""
          }${hasProducts && hasSubcategories ? " and " : ""}${
            hasSubcategories ? "related subcategories" : ""
          }. Please remove or reassign them first.`
        );
      } else {
        try {
          await deleteCategory({ variables: { id: category_id } });
          alert("Category deleted successfully!");
        } catch (err) {
          console.error("Error deleting category", err);
        }
      }
    }
  };

  if (loading || isLoading) return <p>Loading...</p>;

  return (
    <div className="category-detail-container">
      <Toaster />
      <div className="category-detail-container-layout">
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingLeft: "2rem",
          }}
        >
          <button
            onClick={() =>
              navigate("/dashboard/createproduct", { state: { refetch: true } })
            }
            className="category-detail-back-btn"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleUpdate} action="">
          <h2>Category</h2>
          <input
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder={categoryName}
            type="text"
            name=""
            id=""
          />
          <div className="category-detail-btn-container">
            <button type="submit">
              {updateCategoryLoading ? <LoadingButton /> : "Update"}
            </button>
            <button onClick={handleDelete} type="button">
              {deleteLoading ? <LoadingButton /> : "Delete"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CategoryDetail;
